<template>
  <div class="content_vox">
    <div class="content">
      <el-tabs v-model="activeName">
        <el-tab-pane label="售前建议" name="first">
          <el-form label-width="120px" :model="chainData" ref="chainRef" :rules="chainRule">
            <div class="mainTop">
              <el-form-item label="企业名称:" style="width: 470px" prop="companyId">
                <el-select
                  filterable
                  remote
                  reserve-keyword
                  placeholder="请输入关键词"
                  :remote-method="remoteMethodss"
                  v-model="chainData.companyId"
                  @change="companychange"
                >
                  <el-option
                    v-for="item in sceneLists"
                    :key="item.id"
                    :label="item.companyFullName"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="企业行为:" style="width: 470px" prop="behaviorDataList">
                <el-select
                  filterable
                  multiple
                  remote
                  reserve-keyword
                  placeholder="请输入关键词"
                  :remote-method="remoteMethod"
                  v-model="chainData.behaviorDataList"
                >
                  <el-option
                    v-for="item in sceneArr"
                    :key="item.id"
                    :label="item.labelName"
                    :value="item.labelName"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="应用场景:" style="width: 470px" prop="sceneDataList">
                <el-select
                  filterable
                  remote
                  multiple
                  reserve-keyword
                  placeholder="请输入关键词"
                  :remote-method="remoteMethods"
                  v-model="chainData.sceneDataList"
                >
                  <el-option
                    v-for="item in sceneList"
                    :key="item.id"
                    :label="item.sceneName"
                    :value="item.sceneName"
                  >
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="描述：" prop="suggestionDescribe">
                <el-input
                  v-model="chainData.suggestionDescribe"
                  style="width: 80%"
                  type="textarea"
                  :autosize="{ minRows: 4, maxRows: 8 }"
                ></el-input>
                <!-- <Editorbar
                    v-model="chainData.sceneDescribe"
                    :isClear="isClear"
                    style="width: 50%"
                  /> -->
              </el-form-item>
            </div>
          </el-form>

          <div class="bottom">
            <el-button type="primary" @click="saveItem">保存</el-button>
            <el-button @click="cancelItem">取消</el-button>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
    
    <script>
import {
  insertPresalesSuggestion,
  updatePresalesSuggestion,
  selectPartnerById,
  getAllScenarioBasic,
  getAllBehaviorLabel,
  selectByCompanyFullName
} from '@/api/recommendations'

export default {
  name: 'addbehavioral',
  components: {
    //   Editorbar,
  },
  watch: {},
  created() {
      if (this.personData) {
        this.search();
      }
  },
  computed: {
    personData() {
      return Number(this.$route.query.id)
    }
  },
  data() {
    return {
      activeName: 'first',
      sceneList: [], //
      sceneArr: [],
      sceneLists:[],
      isClear: null,
      isShow: false,
      isShowManufacturingCategory: false,
      options: [],
      chainData: {
        behaviorData: '',
        behaviorDataList: [],
        companyFullName: '',
        companyId: '',
        id: 0,
        sceneData: '',
        sceneDataList: [],
        suggestionDescribe: ''
      },

      //企业行为

      chainRule: {
        companyId: [{ required: true, message: '请选择企业', trigger: 'blur' }],
        suggestionDescribe: [{ required: true, message: '请输入售前建议', trigger: 'blur' }],
          }
    }
  },
  methods: {
    companychange(val){
      this.sceneLists.forEach(el=>{
        if(el.id==val){
          this.chainData.companyFullName=el.companyFullName
        }
      })
      
    },
    //   lookClick(val) {
    //     this.chainData.sceneImgUrl = val;
    //   },
    //   saveClick(val) {
    //     this.chainData.sceneImgList = val;
    //   },
      async remoteMethod(query) {
        const  {data:res} = await getAllBehaviorLabel({ name: query });
        if (res.resultCode == 200) {
            let info=JSON.parse(res.message)
          this.sceneArr = info.data;
        }
      },
      async remoteMethods(query) {
         const info={
            name: query,
     }

        const {data:res} = await getAllScenarioBasic(info);
        if (res.resultCode == 200) {
            let info=JSON.parse(res.message)
          this.sceneList = info.data;
        }
      },
      async remoteMethodss(query) {
         const info={
            companyFullName: query,
     }

        const  {data:res} = await selectByCompanyFullName(info);
        
        if (res.resultCode == 200) {
            
          this.sceneLists = res.data;
        }
      },
      

      async search() {
        const res = await selectPartnerById({ id: this.personData });
        if (res.data.resultCode == 200) {
          // Object.assign(this.chainData, res.data);
          this.chainData = res.data.data;
          this.remoteMethod('')
          this.remoteMethods('')
          this.remoteMethodss(this.chainData.companyFullName)
        } else {
          this.$message.error("查询失败");
        }
      },
    saveItem() {
      // console.log(this.radiolist);
      this.$refs.chainRef.validate(async (valid) => {
        if (valid) {
          let res
          if (!this.personData) {
            res = await insertPresalesSuggestion(this.chainData)
            if (res.data.resultCode === 200) {
              this.$message.success('新增成功')
              this.$router.go(-1)
            } else {
              this.$message.error(res.msg)
            }
          } else {
            res = await updatePresalesSuggestion(this.chainData)
            if (res.data.resultCode === 200) {
              this.$message.success('编辑成功')
              this.$router.go(-1)
            }
          }
        }
      })
    },
    cancelItem() {
      this.$router.go(-1)
    }
  }
}
</script>
    
    <style lang="less" scoped>
::v-deep .el-tabs__item {
  padding: 0 25px;
  height: 40px;
  box-sizing: border-box;
  line-height: 40px;
  display: inline-block;
  list-style: none;
  font-size: 17px;
  font-weight: 500;
  color: #448aff;
  position: relative;
}
.el-radio {
  color: #606266;
  cursor: pointer;
  margin-right: 30px;
  margin-bottom: 10px;
  margin-top: 10px;
}
svg {
  font-size: 14px;
}
.mainTop {
  background-color: white;
  padding: 20px 20px 0 20px;
  height: calc(100vh - 300px);
  // margin-bottom: 20px;
}
.circle_flex {
  width: 680px;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.content_vox {
  padding: 20px;
  background-color: #fff;
  overflow: hidden;
}
.content {
  overflow: auto;
  width: 100%;
  background-color: #fff;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px; /*滚动条的圆角*/
  background-color: grey; /*滚动条的背景颜色*/
}
.ess_people {
  display: flex;
  flex-direction: column;
  // overflow: hidden;
  margin: 20px 30px;
  align-content: center;

  .ess_people_list {
    width: 100%;
    margin: 4px 0;
    color: #7c7f8e;
    font-size: 12px;
  }
}
// 上传
/deep/ .el-upload--picture-card {
  width: 200px !important;
  height: 150px !important;
  line-height: 150px !important;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 200px;
  height: 150px;
  // line-height: 150px;
}
/deep/ .el-upload {
  background-color: #fff;
}
/deep/.el-input {
  width: 470px !important;
}
// /deep/.el-textarea {
//   width: 80% !important;
//   // min-height: 40px !important;
// }
/deep/ .el-icon-circle-plus-outline {
  font-size: 31px;
  vertical-align: middle;
  color: #698eef;
  cursor: pointer;
  padding-left: 10px;
}
.bottomStyle {
  /deep/ .el-form-item {
    margin-bottom: 5px;
  }
  /deep/ .el-upload--picture-card {
    width: 150px !important;
    height: 100px !important;
    line-height: 100px !important;
  }
  /deep/ .el-upload-list--picture-card .el-upload-list__item {
    width: 150px;
    height: 100px;
    // line-height: 150px;
  }
  /deep/ .el-upload {
    background-color: #fff;
  }
}
/deep/ .el-icon-remove-outline {
  font-size: 31px;
  vertical-align: middle;
  color: red;
  cursor: pointer;
  padding-left: 10px;
}
.flexStyle {
  display: flex;
  margin: 10px 10px 10px 0;
}
.bottom {
  border-top: 1px solid #dbdbdb;
  background: #fff;
  padding: 20px 20px;
  display: flex;
  justify-content: center;
  .el-button {
    margin: 0 20px;
  }
}
/deep/ .el-tag.el-tag--info {
  background: rgba(30, 159, 255, 0.2);
  color: rgb(30, 159, 255);
}
/deep/ .el-tag.el-tag--info .el-tag__close {
  color: rgb(30, 159, 255);
  background: rgba(30, 159, 255, 0.2);
}
</style>
    